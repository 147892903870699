import { ServerResponse } from "@model/commonModel"
import { ProfileData } from "@model/webformModel"
import { http } from "@api/http"

export function getProfile(
): Promise<ServerResponse<ProfileData>> {
  return http.get("/self/profile")
}

export function updateProfile(
  data: ProfileData
): Promise<ServerResponse<any>> {
  return http.put("/self/profile", data)
}
