const logos = {
  chubb:        require("./chubb.png"),
  logo:         require("./logo.svg").default,
  logoBig:      require("./logo-big.svg").default,
  logoBigPng:   require("./logo-big.png"),
  logoBigWhite: require("./logo-big-white.svg").default,
  logoPng:      require("./logo.png"),
  noImage:      require("./no-image.png"),
  poweredBy:    require("./poweredby.png")
}

export default logos
