import { http } from "./http"
import { idpAuth } from "./idpAuth"
import {
  JWTErrorResponse,
  JWTResponse,
  LoginErrorResponse,
  ServerResponse,
  SessionInfo
} from "@model/commonModel"

declare global {
  interface Window {
    ReadableStream: any
  }
}

export function login(
  userLogin: string,
  password: string,
  deviceKey?: string
): Promise<ServerResponse<SessionInfo> | LoginErrorResponse> {
  return http.post("/login", { deviceKey, login: userLogin, password }, { requiresAuth: false })
}

export function requestSignUp(
  firstName: string,
  lastName: string,
  email: string
): Promise<ServerResponse<never>> {
  return http.post("/sign-up", { email, firstName, lastName }, { requiresAuth: false })
}

export function logout(): Promise<Response> {
  return http.get("/logout")
}

export function checkPasswordResetToken(token: string): Promise<Response> {
  return http.get(`/password-reset/${token}/check`, undefined, { rawResponse: true, requiresAuth: false })
}

export function passwordForgotten(
  email: string
): Promise<ServerResponse<never>> {
  return http.post("/password-forgotten", { email }, { requiresAuth: false })
}

export function passwordReset(params: {
  password: string
  token: string
  jobPosition?: string
  phoneNumber?: string
  platformUseReason?: string
  twoFactorAuthType?: "SMS" | "EMAIL" | null
}) {
  return http.post("/password-reset", params, { ignoreHttpErrors: [400], requiresAuth: false })
}

export function getInfo(): Promise<ServerResponse<SessionInfo>> {
  return http.get("/info", undefined, { ignoreHttpErrors: [401, 403] })
}

export function validateTwoFactorToken(
  token: string,
  method: "sms" | "email"
): Promise<ServerResponse<any>> {
  return http.get(`/2fa/verify/token/${method}/${token}`)
}

export function requestTwoFactor(
  method: "sms" | "email"
): Promise<ServerResponse<any>> {
  return http.get(`/2fa/request/${method}`)
}

export function ssoAuth(): Promise<ServerResponse<string>> {
  return http.get("/saml/auth", undefined, { requiresAuth: false })
}

export function getAuthorizeUrl(
  integrationId: string
): Promise<ServerResponse<string>> {
  return http.get(`/saml2/${integrationId}/authorize-url`, undefined, { requiresAuth: false })
}

export async function requestJWT(
  userLogin: string,
  password: string,
  deviceKey?: string
): Promise<JWTResponse | JWTErrorResponse> {
  return idpAuth.getAccessToken(userLogin, password, deviceKey)
}

export async function requestUserSession(): Promise<
  ServerResponse<SessionInfo> | LoginErrorResponse
> {
  return http.get("/self/session")
}
