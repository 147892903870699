import { ServerResponse } from "@model/commonModel"
import { http } from "@api/http"
import { SmarthomeRole } from "@model/devicesModel"

export function getCountryCodes(): Promise<
  ServerResponse<any> & { twoDigitIso: "string"; code: "string" }[]
> {
  return http.get("/country_codes")
}

export function getRoles(): Promise<ServerResponse<SmarthomeRole[]>> {
  return http.get("/smarthome/roles")
}

export function getAccessReasons(): Promise<ServerResponse<string[]>> {
  return http.get("/smarthome/access-reasons")
}
