import FORM_ID from "./forms"

export const API_URL = process.env.REACT_APP_API_URL || "/api"
export const BASE_PATH = process.env.REACT_APP_BASE_PATH || "/"
export const APP_VERSION = process.env.REACT_APP_VERSION || "4.2.0BLZT"

export const INACTIVITY_LOGOUT_TIME_MILLISECONDS = 30 * 60 * 1000 // 30min
export const SESSION_REFRESH_INTERVAL = 1 * 60 * 1000 // 1 min - how often to "ping" backend to keep session alive.
export const EXPIRE_DEV_SESSION = false // don't logout in dev mode (allows to continue working with existing data when server shuts down)

export const DATE_FORMAT = "dd/MM/yyyy"
export const TIME_FORMAT = "HH:mm"
export const DATE_TIME_FORMAT = "dd/MM/yyyy HH:mm"
export const DATE_TIME_FORMAT_HUMAN = "dd MMMM HH:mm"

export const JAVASCRIPT_MAP_API_KEY =
  process.env.GOOGLE_MAPS_API_KEY || "AIzaSyDOvcmp2PelSUwXqNdz7ikw-esVCWw9uYY"

export const HUBSPOT_API = {
  LEAD_FORM_ID: "29148587-330d-41c0-a2f6-43a7b598faf7",
  PORTAL_ID:    "8437638"
}

export const DATE_FORMAT_FNS = {
  DATE:      "dd/MM/yyyy",
  DATE_TIME: "dd/MM/yyyy HH:mm",
  TIME:      "HH:mm"
}

export const COMMON_DEBOUNCE_DURATION = 700
export const VALIDATION_DEBOUNCE_DURATION = 1500
export const PHONE_NUMBER = "0370 770 6886"
export const PHONE_NUMBER2 = "0370 770 6880"

export const SM_BREAKPOINT_WIDTH = 576
export const MOBILE_BREAKPOINT_WIDTH = 768
export const LARGE_BREAKPOINT_WIDTH = 992
export const XL_BREAKPOINT_WIDTH = 1200
export const XXL_BREAKPOINT_WIDTH = 1400

export const FAKE_LOGIN = false
export const IGNORE_PROFILE_THEME = false
export const DEBUG_HTTP = false

export const NO_ADDRESS_OPTION = "My address doesn't appear in the list"
export const EMAIL_CLIENT = "clientservices@keyholding.com"

export const DEFAULT_SENTRIKEY_ACCESS_GROUP = "Smart Key Safe Access"

export const JOBS_PAGING_STEP = 50
export const INVOICES_PAGING_STEP = 50
export const AUDITS_PAGING_STEP = 50
export const MAX_JOBS_LIMIT = 20000

export const BOOKING_START_HOURS_LIMIT: number = 1
export const BOOKING_MAX_SHIFT_HOURS = 13
export const BOOKING_MAXIMUM_APPOINTMENTS = 14
export const BOOKING_MAXIMUM_JOBS = 100
export const BOOKING_NOTE_MAX_LENGTH = 1000
export const BOOKING_PO_MAX_LENGTH = 50
export const BOOKING_DETAIL_MAX_LENGTH = 63
export const BOOKING_DETAIL_LONG_MAX_LENGTH = 1000

export const FILE_UPLOAD_MAX_SIZE = 50 * 1024 * 1024
export const IMAGE_UPLOAD_MAX_SIZE = 7 * 1024 * 1024

export const DEFAULT_MAX_LENGTH = 63
export const JOB_NOTE_MAX_LENGTH = 5000
export const AI_FIELD_LENGTH = 2500

export const MAINTENANCE_MODE = false
export const MAINTENANCE_WARNING = false
export const MAINTENANCE_DATE_STRING = "30th September 13:00 GMT"
export const MAINTENANCE_DURATION_STRING = "approx. 60 minutes"

export const REGEXPS = {
  // eslint-disable-next-line unicorn/better-regex,no-useless-escape
  DATE: /(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)$/,
  EMAIL:
    // eslint-disable-next-line unicorn/better-regex,no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // eslint-disable-next-line unicorn/better-regex
  FOBID: /[0]*[A-Fa-f0-9]{12,16}/,
  FOBID_UNPARSED:
    // eslint-disable-next-line unicorn/better-regex
    /[ 0]*(([A-Fa-f0-9]\s*){0,4})((\s*[A-Fa-f0-9]\s*){4})((\s*[A-Fa-f0-9]\s*){4})((\s*[A-Fa-f0-9]\s*){4})/,
  PASSWORD:
    // eslint-disable-next-line unicorn/better-regex
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/,
  // eslint-disable-next-line unicorn/better-regex
  PHONE_SIMPLE: /^(\+|00)(?:[0-9] ?){6,14}[0-9]$/,
  PHONE_UK:
    // eslint-disable-next-line unicorn/better-regex
    /^(?:(?:(?:00\s?|\+)44\s?)|(?:\(?0))(?:\d{2}\)?\s?\d{4}\s?\d{4}|\d{3}\)?\s?\d{3}\s?\d{3,4}|\d{4}\)?\s?(?:\d{5}|\d{3}\s?\d{3})|\d{5}\)?\s?\d{4,5})$/,
  POSTCODE:
    // eslint-disable-next-line unicorn/better-regex,no-useless-escape
    /^([A-PR-UWYZa-pr-uwyz]([0-9]{1,2}|([A-HK-Ya-hk-y][0-9]|[A-HK-Ya-hk-y][0-9]([0-9]|[ABEHMNPRV-Yabehmnprv-y]))|[0-9][A-HJKS-UWa-hjks-uw])\ {0,1}[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}|([Gg][Ii][Rr]\ 0[Aa][Aa])|([Ss][Aa][Nn]\ {0,1}[Tt][Aa]1)|([Bb][Ff][Pp][Oo]\ {0,1}([Cc]\/[Oo]\ )?[0-9]{1,4})|(([Aa][Ss][Cc][Nn]|[Bb][Bb][Nn][Dd]|[BFSbfs][Ii][Qq][Qq]|[Pp][Cc][Rr][Nn]|[Ss][Tt][Hh][Ll]|[Tt][Dd][Cc][Uu]|[Tt][Kk][Cc][Aa])\ {0,1}1[Zz][Zz]))$/,
  // eslint-disable-next-line unicorn/better-regex
  SERVICE_TRAC: /[A-Z]{2}-[A-Z0-9]{4}-[A-Z0-9]{4}/,
  SORT_CODE:    /\d{2}-\d{2}-\d{2}/,
  // eslint-disable-next-line unicorn/better-regex
  TIME:         /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
  // eslint-disable-next-line unicorn/better-regex
  UUID:         /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/
}
export const INPUT_MASK = {
  PHONE_PREFIXLESS: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  PHONE_UK: [
    "+",
    "4",
    "4",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],
  SERVICE_TRAC: [
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z]/,
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z]/,
    "-",
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z0-9]/,
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z0-9]/,
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z0-9]/,
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z0-9]/,
    "-",
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z0-9]/,
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z0-9]/,
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z0-9]/,
    // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z0-9]/
  ]
}

// user ids whose validation errors are displayed
// as red outlines on inputs instead of red circles
// near them
export const NO_CIRCLE_FORMS = {
  [FORM_ID.SELECT_ACCOUNT_FOR_NEW_PROPERTY]: true,
  [FORM_ID.CHUBB_CONTRACT]:                  true,
  [FORM_ID.CHUBB_CUSTOMER]:                  true,
  [FORM_ID.ADD_NOTE]:                        true,
  AccessGroupsForm:                          true,
  AccountDetailsForm:                        true,
  ActivateLockForm:                          true,
  CamerasGroup:                              true,
  DeviceUserForm:                            true,
  DiscountForm:                              true,
  InstructionsForm:                          true,
  MakerForm:                                 true,
  MyProfile:                                 true,
  RecipientForm:                             true,
  SiteContactForm:                           true,
  SmartlocksForm:                            true,
  TimeProfileForm:                           true,
  ViewEditForm:                              true,
  "assessment-password":                     true,
  generateCode:                              true,
  pin:                                       true,
  serviceDetails:                            true,
  test2:                                     true,
  twoFactorSms:                              true
}

// google optimize experiments stuff
export const OPTIMIZE_EXPERIMENTS = {
  WEBFORM_CONTACTS: {
    event: "optimize.activate.webformExperiment",
    id:    "xSFORIXqRuS2a7whGDyYEA"
  }
}

export const REACT_NBSP = "\u00A0"
export const REACT_ZWSP = "\u200b"

export const AMULET_ACCOUNT = 39290
export const AMULET_TYPEFORM_ID = "oW04GRMh"

export const TKC_SCHEDULES_TYPEFORM_ID = "u859JOmE"
