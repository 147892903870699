import { detectSafariIos } from "./detectBrowser"

declare global {
  interface CSSStyleDeclaration {
    msUserSelect: string
    msOverflowStyle: string
  }
}

export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement("div")
  outer.style.visibility = "hidden"
  outer.style.overflow = "scroll" // forcing scrollbar to appear
  outer.style.msOverflowStyle = "scrollbar" // needed for WinJS apps
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement("div")
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  // Removing temporary elements from the DOM
  if (outer.parentNode) {
    outer.parentNode.removeChild(outer)
  }

  return scrollbarWidth
}

let savedScrollTop = 0

export const offsetHtmlScrollbar = (bool: boolean) => {
  if (!bool) {
    enableScroll()
  } else {
    disableScroll()
  }
}

function disableScroll() {
  document.body.style.overflowY = "hidden"
  if (detectSafariIos()) {
    savedScrollTop = window.pageYOffset
    document.documentElement.style.overflowY = "hidden"
    document.documentElement.style.position = "relative"
    document.documentElement.style.height = "var(--fullheight)"
    document.body.style.position = "relative"
    document.body.style.height = "var(--fullheight)"
    document.body.scrollTop = savedScrollTop
    document.documentElement.scrollTop = savedScrollTop
  }
  if (document.body.scrollHeight > window.innerHeight) {
    document.body.style.marginRight = getScrollbarWidth() + "px"
  }
}

function enableScroll() {
  document.body.style.overflowY = ""
  document.body.style.marginRight = ""
  if (detectSafariIos()) {
    document.documentElement.style.overflowY = ""
    document.documentElement.style.position = ""
    document.documentElement.style.height = ""
    document.body.style.position = ""
    document.body.style.height = ""
    window.scrollTo({ top: savedScrollTop })
    setTimeout(() => {
      window.scrollTo({ top: savedScrollTop })
    }, 0)
  }
}
