import { API_URL, FAKE_LOGIN, DEBUG_HTTP } from "@consts/config"
import { authState } from "@state/authState"
import { idpAuth } from "@api/idpAuth"
import { Mutex } from 'async-mutex'
import { Subject } from "rxjs"
import { Downloadee } from "@model/jobModel"
import { downloadSubject } from "@state/downloadState"
import { blobToFile } from "@utils/blobToFile"


// Error events to notify listeners about http errors
const errorSubject = new Subject<string>()
export const errorEvents = errorSubject.asObservable()

// Mutex to avoid multiple token refreshes
const mutex = new Mutex()

/**
 * Service to handle HTTP requests to the backend.
 */
export const http = (() => {


  /**
   * Sends a POST request to the given URL.
   * @param url the request URL
   * @param body the request body
   * @param requiresAuth false if the request does not require authentication
   * @returns
   */
  const post = async (url: string, body: any, options?: RequestOptions): Promise<any> => {
    const response = _execute('POST', url, body, { ...defaultRequestOptions, ...options })
    if (options?.rawResponse) {
      return response
    }
    return _handleResponse(response, options?.ignoreHttpErrors)
  }

  /**
   * Sends a GET request to the given URL.
   * @param url the request URL
   * @param requiresAuth false if the request does not require authentication
   * @returns the response of the request
   */
  const get = async (url: string, params?: Record<string, any>, options?: RequestOptions): Promise<any> => {
    let queryString = ''
    if (params) {
      queryString = _mapToQueryString(params)
      url += url.includes('?') ? '&' : '?' + queryString
    }
    const response = _execute('GET', url, undefined, { ...defaultRequestOptions, ...options })
    if (options?.rawResponse) {
      return response
    }
    return _handleResponse(response, options?.ignoreHttpErrors)
  }

  /**
   * Sends a DELETE request to the given URL.
   * @param url the request URL
   * @param requiresAuth false if the request does not require authentication
   * @returns the response of the request
   */
  const del = async (url: string, body: any = undefined, options?: RequestOptions): Promise<any> => {
    // please do not use body for DELETE requests as it contradicts the HTTP standard
    const response = _execute('DELETE', url, body, { ...defaultRequestOptions, ...options })
    if (options?.rawResponse) {
      return response
    }
    return _handleResponse(response, options?.ignoreHttpErrors)
  }

  /**
   * Sends a PUT request to the given URL.
   * @param url the request URL
   * @param body the request body
   * @param requiresAuth false if the request does not require authentication
   * @param stringifyBody true if the body should be stringified
   * @returns the response of the request
   */
  const put = async (url: string, body: any, options?: RequestOptions): Promise<any> => {
    const response = _execute('PUT', url, body, { ...defaultRequestOptions, ...options })
    if (options?.rawResponse) {
      return response
    }
    return _handleResponse(response, options?.ignoreHttpErrors)
  }

  /**
   * Sends a PATCH request to the given URL.
   * @param url the request URL
   * @param body the request body
   * @param requiresAuth false if the request does not require authentication
   * @param stringifyBody true if the body should be stringified
   * @returns the response of the request
   */
  const patch = async (url: string, body: any, options?: RequestOptions): Promise<any> => {
    const response = _execute('PATCH', url, body, { ...defaultRequestOptions, ...options })
    if (options?.rawResponse) {
      return response
    }
    return _handleResponse(response, options?.ignoreHttpErrors)
  }

  /**
   * Downloads a file from the given URL.
   * @param url download URL
   * @param mimeType expected mime type of the file
   * @param options request options
   * @returns file
   */
  const downloadFile = async (url: string, mimeType: string, options?: RequestOptions): Promise<any> => {
    const response = _execute('GET', url, undefined, { ...defaultRequestOptions, ...options })
    if (options?.rawResponse) {
      return response
    }
    if (!window.ReadableStream) {
      return _handleResponseAsStreamIE(response, mimeType)
    } else {
      return _handleResponseAsStream(response, mimeType)
    }
  }

  /**
   * Uploads a file to the given URL.
   * @param url upload URL
   * @param data form data
   * @param onProgress progress callback
   * @returns request and response promise
   */
  const uploadFile = (url: string, data: FormData,
    onProgress?: (progress: number) => void): { request: XMLHttpRequest; res: Promise<XMLHttpRequest> } => {

    const request = new XMLHttpRequest()
    request.open("POST", API_URL + url)
    request.setRequestHeader("credentials", "include")

    // upload progress event
    if (onProgress && request.upload) {
      request.upload.addEventListener("progress", function (e) {
        // upload progress as percentage
        const percent_completed = (e.loaded / e.total) * 100
        onProgress(percent_completed)
      })
    }

    const res = new Promise<XMLHttpRequest>((resolve, reject) => {
      // request finished event
      request.addEventListener("load", function () {
        resolve(request)
      })
      request.addEventListener("error", function () {
        reject(request)
      })
    })

    // send POST request to server
    request.send(data)

    return { request, res }
  }

  /**
   * Checks if the user is authenticated with keycloak.
   * @returns true if user authenticated with keycloak, false otherwise
   */
  const _isKeycloakMode = (): boolean => {
    return localStorage.getItem("JWT_KC_MODE") === 'true'
  }

  const _refreshToken = async (): Promise<void> => {
    if (_isKeycloakMode()) {
      return _refreshTokenWithKeycloak()
    } else {
      return _refreshTokenWithIdP()
    }
  }

  /**
   * Refreshes the token with the keycloak identity provider.
   */
  const _refreshTokenWithKeycloak = async (): Promise<void> => {
    await authState.keycloak?.updateToken(-1).then((refreshed) => {
      if (refreshed) {
        localStorage.setItem("JWT_ACCESS", authState.keycloak?.token || '')
        localStorage.setItem("JWT_REFRESH", authState.keycloak?.refreshToken || '')
      }
    })
  }

  /**
   * Refreshes the token with the in-house identity provider TKC IdP.
   */
  const _refreshTokenWithIdP = async (): Promise<void> => {
    await mutex.acquire()
    try {
      const refreshToken = localStorage.getItem("JWT_REFRESH") || ''
      if (refreshToken === 'undefined' || !refreshToken) {
        return
      }
      const deviceKey = localStorage.getItem("KHC_DEVICE_KEY") || ''
      const jwtResponse = await idpAuth.getAccessTokenByRefreshToken(refreshToken, deviceKey)

      localStorage.setItem("JWT_ACCESS", jwtResponse.access_token)
      localStorage.setItem("JWT_REFRESH", jwtResponse.refresh_token)
      localStorage.setItem("JWT_EXPIRY", String(Date.now() + (jwtResponse.expires_in * 1000)))
    } finally {
      mutex.release()
    }
  }

  /**
   * Resolves the token from the local storage (which stored by keycloak or idp).
   * @returns the token
   */
  const _resolveToken = async (): Promise<string | null | undefined> => {
    return localStorage.getItem("JWT_ACCESS")
  }

  /**
   * Genertic HTTP method to execute requests.
   * It handles the token refresh logic and retries the request if the token has expired.
   *
   * @param method the request method (e.g. GET, POST)
   * @param url the request URL
   * @param body the request body
   * @param options request options
   * @returns the response of the request
   */
  const _execute = async (
    method: string,
    url: string,
    body: any,
    options: RequestOptions,
    retryCount: number = 0): Promise<Response> => {

    // for testing purposes, can be enabled in config.ts
    if (FAKE_LOGIN) {
      return Promise.reject(Error("FAKE LOGIN"))
    }

    const baseUrl = API_URL.endsWith('/') ? API_URL : `${API_URL}/`

    // if the URL is not absolute, prepend the base URL
    if (!url.startsWith(baseUrl) && !url.startsWith('http')) {
      url = baseUrl + (url.startsWith('/') ? url.substring(1) : url)
    }

    try {
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')

      if (options.headers) {
        Object.entries(options.headers)
          .forEach(([key, value]) => headers.append(key, value))
      }

      if (options.requiresAuth) {
        await _resolveToken()
          .then(token => {
            headers.append('Authorization', 'Bearer ' + token)
          })
      }

      const fetchOptions: RequestInit = {
        body: options.stringifyBody ? JSON.stringify(body) : body,
        headers,
        method
      }

      const response = await fetch(url, fetchOptions)
      if (DEBUG_HTTP) {
        console.log(`>> ${method} ${url} | ${response.status} ${response.statusText}`)
      }

      if (response.status === 401 && options.requiresAuth && retryCount < 1) {
        // token expired, refresh it
        try {
          await _refreshToken()
          // retry the request with the new token
          return _execute(method, url, body, options, retryCount + 1)
        } catch (error) {
          // fail silently
        }
      }

      return response
    } catch (error) {
      console.error('Request failed:', error)
      throw error
    }
  }

  /**
   * Handles the response of a request.
   * @param promise request promise
   * @param ignoreHttpCodes unhandled status codes
   * @returns
   */
  const _handleResponse = async (promise: Promise<Response>, ignoreHttpErrors?: number[]): Promise<any> => {
    return promise
      .then(async (resp) => {
        if (ignoreHttpErrors && ignoreHttpErrors.indexOf(resp.status) > -1) {
          try {
            const json = await resp.json()
            return { code: resp.status, ...json }
          } catch {
            return { code: resp.status }
          }
        } else {
          switch (resp.status) {
            case 400:
              errorSubject.next(API_ERROR.BAD_REQUEST)
              throw new Error(API_ERROR.BAD_REQUEST)
            case 403:
              errorSubject.next(API_ERROR.FORBIDDEN)
              throw new Error(API_ERROR.FORBIDDEN)
            case 404:
              errorSubject.next(API_ERROR.NOT_FOUND)
              throw new Error(API_ERROR.NOT_FOUND)
            case 500:
              errorSubject.next(API_ERROR.INTERNAL_ERROR)
              throw new Error(API_ERROR.INTERNAL_ERROR)
            case 503:
              errorSubject.next(API_ERROR.UNAVAILABLE)
              throw new Error(API_ERROR.UNAVAILABLE)
            case 504:
              errorSubject.next(API_ERROR.TIMEOUT)
              throw new Error(API_ERROR.TIMEOUT)
          }
        }
        let json
        try {
          json = await resp.json()
        } catch (error) {
          if (resp.status === 200) {
            json = { success: true }
          } else {
            // assuming backend is down if no json is returned and status code is not 200
            errorSubject.next(API_ERROR.UNAVAILABLE)
            throw new Error(API_ERROR.UNAVAILABLE)
          }
        }
        if (json.error) {
          console.warn("❌ API error:")
          console.warn(JSON.stringify(json, null, 1))
        }
        return json
      })
      .catch((e: Error) => {
        if (e.message === "Failed to fetch") {
          return { data: API_ERROR.FETCH_FAILURE, error: true }
        }
        return { data: e.message, error: true }
      })
  }

  /**
   * Handles the response of a request as a stream in way compatible with IE.
   * @param promise request promise
   * @param mimeType explicit mime type
   * @returns
   */
  const _handleResponseAsStreamIE = async (promise: Promise<Response>, mimeType?: string): Promise<File | void> => {
    let download: Downloadee = {
      downloaded: 0,
      file:       null,
      fileName:   "",
      total:      0
    }
    const key = new Date().getTime().toString()
    downloadSubject.next({ download, key })

    return promise
      .then(async (response) => {
        _throwDownloadErrorIfFailed(response)
        const contentLength = response.headers.get("Content-Length")
          ? parseFloat(response.headers.get("Content-Length")!)
          : 0
        const fileName = _generateFilenameByMimeType(response, mimeType)
        download = {
          downloaded: 0,
          file:       null,
          fileName,
          total:      contentLength
        }
        downloadSubject.next({ download, key })
        return response.blob()
      })
      .then((data) => {
        downloadSubject.next({
          download: { ...download, file: data },
          key
        })
      })
      .catch(() => {
        downloadSubject.next({ delete: true, key })
      })
  }

  /**
   * Handles the response of a request as a stream.
   * @param promise request promise
   * @param mimeType explicit mime type
   * @returns file
   */
  const _handleResponseAsStream = async (promise: Promise<Response>, mimeType: string): Promise<File | void> => {
    let download: Downloadee = {
      downloaded: 0,
      file:       null,
      fileName:   "",
      total:      0
    }
    const key = new Date().getTime().toString()
    downloadSubject.next({ download, key })

    return promise
      .then(async (resp) => {
        _throwDownloadErrorIfFailed(resp)

        download = {
          downloaded: 0,
          file:       null,
          fileName:   "",
          total:      0

        }
        // todo: use 'new ReadableStream' and 'start(controller)'
        const reader = resp.body!.getReader()
        const contentLength = resp.headers.get("Content-Length")
          ? parseFloat(resp.headers.get("Content-Length")!)
          : 0
        const fileName = _generateFilenameByMimeType(resp, mimeType)
        let receivedLength = 0

        download.fileName = fileName
        download.total = contentLength
        downloadSubject.next({ download, key })
        const chunks: Uint8Array[] = []
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }
          if (!value) {
            return
          }
          chunks.push(value)
          receivedLength += value.length
          download.downloaded = receivedLength
          downloadSubject.next({ download, key })
        }
        const chunksAll = new Uint8Array(receivedLength)
        let position = 0
        for (const chunk of chunks) {
          chunksAll.set(chunk, position)
          position += chunk.length
        }
        // EDGE doesn't support 'new File' constructor
        const blobFile = new Blob([chunksAll.buffer], {
          type: mimeType
        })
        const file = blobToFile(blobFile, fileName)
        download.file = file
        downloadSubject.next({ download, key })
        return file
      })
      .then((data) => data)
      .catch(() => {
        downloadSubject.next({ delete: true, key })
      })
  }

  /**
   * Generate a file based on mime type and content disposition.
   * @param response download response
   * @param mimeType explicit mime type
   * @returns
   */
  const _generateFilenameByMimeType = (response: Response, mimeType: string = '') => {
    const contentDisposition = response.headers.get("Content-Disposition")
    if (!mimeType) {
      const contentType = response.headers.get("Content-Type")
      mimeType = (contentType && contentType.split(";")[0]) || "application/octet-stream"
    }

    const baseFileName = `KHC_File_${new Date().getTime().toString()}`
    const extension = MIME_TYPE_EXT_MAPPING.get(mimeType)
    let fileName = `${baseFileName}.${extension}`

    if (contentDisposition) {
      const arr = contentDisposition.split('filename="')
      if (arr.length > 1) {
        fileName = arr[1].slice(0, arr[1].length - 1)
      }
    }

    return fileName
  }

  /**
   * Throws an error if the download failed.
   * @param response download response
   */
  const _throwDownloadErrorIfFailed = (response: { status: number; body: any }) => {
    switch (response.status) {
      case 403:
        errorSubject.next(API_ERROR.NOT_ALLOWED)
        throw new Error(API_ERROR.NOT_ALLOWED)
      case 404:
        errorSubject.next(API_ERROR.NOT_FOUND)
        throw new Error(API_ERROR.NOT_FOUND)
      case 500:
        errorSubject.next(API_ERROR.INTERNAL_ERROR)
        throw new Error(API_ERROR.INTERNAL_ERROR)
      case 503:
        errorSubject.next(API_ERROR.UNAVAILABLE)
        throw new Error(API_ERROR.UNAVAILABLE)
      case 504:
        errorSubject.next(API_ERROR.DOWNLOAD_TIMEOUT)
        throw new Error(API_ERROR.DOWNLOAD_TIMEOUT)
    }
    if (!response.body || response.status !== 200) {
      errorSubject.next(API_ERROR.UNKNOWN)
      throw new Error(API_ERROR.UNKNOWN)
    }
  }

  /**
   * Maps the given parameters to a query string.
   * @param params query parameters
   * @returns query string
   */
  const _mapToQueryString = (params: Record<string, any>): string => {
    return Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== null)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
  }

  return {
    _handleResponse,
    del,
    downloadFile,
    get,
    patch,
    post,
    put,
    uploadFile
  }
})()


interface RequestOptions {
  headers?: Record<string, string>
  // ignore http errors
  ignoreHttpErrors?: number[]
  // allows to disable authentication for a request
  requiresAuth?: boolean
  // allows to disable body stringification (non-json request)
  stringifyBody?: boolean
  // do not parse the response
  rawResponse?: boolean
}

const defaultRequestOptions: RequestOptions = {
  requiresAuth:     true,
  stringifyBody:    true
}

export enum API_ERROR {
  NOT_FOUND = "The server could not find the requested resource",
  FORBIDDEN = "Session expired. Logging out...",
  NOT_ALLOWED = "Access denied - insufficient privileges",
  INTERNAL_ERROR = "The server has encountered an internal error",
  BAD_REQUEST = "The server has refused to process a request",
  UNAVAILABLE = "Couldn't reach the server",
  DOWNLOAD_TIMEOUT = "Download timed out, please try again",
  TIMEOUT = "Request timed out, please try again",
  UNKNOWN = "Connection error, please try again",
  FETCH_FAILURE = "Couldn't reach the server, please check your connection and try again"
}

const MIME_TYPE_EXT_MAPPING = new Map([
  ["application/octet-stream", ""],
  ["application/pdf", "pdf"],
  ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "docx"],
  ["application/msword", "doc"],
  ["image/bmp", "bmp"],
  ["image/jpeg", "jpg"],
  ["image/gif", "gif"],
  ["application/json", "json"],
  ["text/csv", "csv"],
  ["text/plain", "txt"],
  ["application/vnd.ms-excel", "xls"],
  ["text/html", "html"]
])
