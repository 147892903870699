import React, { useEffect, useState } from "react"
import { Subject } from "rxjs"
import { offsetHtmlScrollbar } from "@utils/getScrollbarWidth"
import { Modal, modalClosedEvent, ModalProps } from "../modal/Modal"

export let MODAL_ZINDEX = 0

const modalToggleSubject = new Subject<{
  id: string
  component?: any
  open: boolean
  closeAll?: boolean
  closeable?: boolean
  props?: any
  popup?: boolean
}>()

export const toggleModal = <
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>,
  U extends boolean
>(
  body: U extends false
    ? {
        id: string
        open: U
        closeAll?: boolean
      }
    : {
        id: string
        component?: T
        open: U
        closeAll?: boolean
        closeable?: boolean
        props?: Omit<React.ComponentProps<T>, keyof ModalProps> // gotta be optional, as props can contain just optional types,
        popup?: boolean
      }
) => {
  modalToggleSubject.next(body)
}

// not for outside use
export const modalCloseSubject = new Subject<{
  id: string
}>()

export const modalZIndexSubject = new Subject<{
  id: string
  zIndex: number
}>()

export const Modals = () => {
  const [modals, setModals] = useState<
    {
      id: string
      compId: string
      copy: number
      open: boolean
      closeAll?: boolean
      closeable?: boolean
      component: any
      props: any
      popup?: boolean
    }[]
  >([])

  useEffect(() => {
    const sub2 = modalClosedEvent.subscribe((id) => {
      const newModals = [...modals]
      newModals.forEach((modal, index) => {
        if (modal.id === id) {
          newModals.splice(index, 1)
        }
      })
      setModals(newModals)
    })
    const sub = modalToggleSubject.subscribe(
      ({ id, open, props, component, closeable, popup, closeAll }) => {
        if (open) {
          if (!popup) {
            offsetHtmlScrollbar(true)
          }
          MODAL_ZINDEX++
          const newModals = [...modals]
          let howMany = 0
          newModals.forEach((modal) => {
            if (modal.compId === id) {
              howMany++
            }
          })
          newModals.push({
            closeable,
            compId: id,
            component,
            copy:   howMany,
            id:     id + (howMany ? howMany : ""),
            open:   true,
            popup,
            props
          })
          setModals(newModals)
        } else {
          let latestIndex = 0
          // send close event for the latest copy of a modal, or for each one if closeAll
          modals.forEach((modal) => {
            if (closeAll && modal.compId === id) {
              modalCloseSubject.next({
                id: id + (modal.copy ? modal.copy : "")
              })
            } else if (modal.compId === id && modal.copy > latestIndex) {
              latestIndex = modal.copy
            }
          })
          if (!closeAll) {
            modalCloseSubject.next({
              id: id + (latestIndex ? latestIndex : "")
            })
          }
        }
      }
    )
    return () => {
      sub.unsubscribe()
      sub2.unsubscribe()
    }
  }, [modals])

  return (
    <div className="Modals">
      {modals.map((modal) => {
        const { id, popup, component, closeable, props, compId } = modal
        return (
          <Modal
            key={id}
            id={id}
            className={compId}
            content={component}
            closeable={closeable}
            props={props}
            popup={popup}
          />
        )
      })}
    </div>
  )
}
