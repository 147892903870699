import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "core-js/features/array"
import "core-js/features/object/entries"
import "core-js/features/promise"
import "core-js/features/string/repeat"
import "core-js/features/symbol"
import "./eventPolyfill"

import { API_URL } from "@consts/config"
import "./shared/styles/index.css"
import "./shared/styles/margins.scss"
import "./shared/styles/typography.scss"
import {
  DARK_MODE,
  LoadedImage,
  What3WordsResponse
} from "@api/model/commonModel"
import { generatePalette } from "./init/themeHandler"
import { initBootsplash, initSplashLogo } from "./init/initSplash"
import { convertToBase64Png, loadImage } from "@utils/imageUtils"
import { popupSubject } from "@state/appState"
import { API_ERROR } from "@api/http"
import logos from "@images/logos"
import { getWhiteLabelProfiles } from "@api/white-label"

declare global {
  interface Window {
    hideBootsplash: () => any
    hideBootsplashSpinner: () => any
    showBootsplashCheckmark: () => any
    setBootsplashText: (s: string) => any
    hj: (...args: any[]) => any
    intlTelInputUtils: any
    dataLayer: any[]
    gtag: (...args: any[]) => any
    google_optimize?: any
    zE: (
      widget: "messenger" | "messenger:on" | "messenger:set",
      event: string,
      args?: any
    ) => any
    zESettings: any
    $zopim: any
    titleHistory: { title: string }[]
    toggleDarkMode: (darkModePreference?: DARK_MODE) => void
    theme?: {
      logo: LoadedImage
      urlName: string | null
      primary: string
      accent: string
      showPoweredBy: boolean
      showWidget: boolean
      keycloakEnabled: boolean
    }
    themeLogo?: string // url, for themed logos
    themeProfile?: string
    originTimestamp: number
    what3words: {
      api: {
        convertTo3wa: ({
          lat,
          lng
        }: {
          lat: number
          lng: number
        }) => Promise<What3WordsResponse>
        convertToCoordinates: (words: string) => Promise<What3WordsResponse>
      }
    }
  }

  interface Navigator {
    msSaveOrOpenBlob: (file: File, name: string) => void
  }
}

const prepareChubbWebformTheme = async () => {
  const defaultPrimary = "#0067b1"
  const defaultAccent = "#0067b1"
  generatePalette(defaultPrimary, defaultAccent)
  const img = await loadImage(logos.chubb)
  window.theme = {
    accent:           defaultAccent,
    keycloakEnabled: false,
    logo:             img,
    primary:          defaultPrimary,
    showPoweredBy:    true,
    showWidget:       true,
    urlName:          null
  }
  generateFavicon(img)
}

const checkProfileAndTheme = async () => {
  if (location.pathname.indexOf("join-chubb") > -1) {
    await prepareChubbWebformTheme()
    return
  }

  const defaultPrimary = "#fa5f00"
  const defaultAccent = "#ffddcc"

  const res = await getWhiteLabelProfiles()
  if (res && !res.error) {
    const profiles = res.data
    for (const prf of profiles) {
      if (location.pathname.split("/").indexOf(prf.urlName) > -1) {
        generatePalette(`#${prf.primaryColor}`, `#${prf.secondaryColor}`)
        const img = await loadImage(API_URL + prf.logo)
        window.theme = {
          accent:           `#${prf.secondaryColor}`,
          keycloakEnabled:  prf.keycloakEnabled,
          logo:             img,
          primary:          `#${prf.primaryColor}`,
          showPoweredBy:    prf.showPoweredBy,
          showWidget:       prf.showWidget,
          urlName:          prf.urlName
        }
        generateFavicon(img)
        return
      }
    }
  }
  generatePalette(defaultPrimary, defaultAccent)
}

const generateFavicon = (img: LoadedImage) => {
  ;[...document.getElementsByTagName("link")].forEach((element) => {
    if (
      (element as HTMLLinkElement).href &&
      (element as HTMLLinkElement).href.indexOf("icon") > -1
    ) {
      element.parentElement!.removeChild(element)
    }
  })
  const favlink = document.createElement("link")
  favlink.setAttribute("rel", "shortcut icon")
  favlink.setAttribute(
    "href",
    convertToBase64Png(img.image, { contain: true, height: 32, width: 32 })
  )
  document.getElementsByTagName("head")[0].appendChild(favlink)
}

export const logError = (err: any, userString?: string) => {
  if (userString) {
    if (err.message !== API_ERROR.FORBIDDEN) {
      popupSubject.next(`${userString}: \n${err?.message || "Unknown error"}`)
    }
  }

  const { message, name, stack } = err
  const date = new Date()
  const ev = {
    event: "error",
    message,
    name,
    stack,
    time:  date.toUTCString()
  }
  window.dataLayer.push(ev)
}

const setupErrorLogging = () => {
  window.originTimestamp = new Date().getTime()
  window.onerror = (message, source, lineno, colno, error) => {
    console.warn(message, source, lineno, colno, error)
    const date = new Date()
    const ev = {
      event:    "exception",
      location: `line ${lineno}, col ${colno}`,
      message,
      source,
      time:     date.toUTCString()
    }
    window.dataLayer.push(ev)
    return true
  }
}

;(async () => {
  setupErrorLogging()
  await initBootsplash()
  await checkProfileAndTheme()
  await initSplashLogo()
  // eslint-disable-next-line no-empty-pattern
  const {} = await import(
    /* webpackChunkName: "polyfills" */ "./init/initPolyfills"
  )
  const { initApp } = await import(
    /* webpackChunkName: "app-init" */ "./init/initApp"
  )
  await initApp()
})()

export {}
