import { LoadedImage } from "@api/model/commonModel"
import logos from "@images/logos"

function fit(contains) {
  return (
    parentWidth,
    parentHeight,
    childWidth,
    childHeight,
    scale = 1,
    offsetX = 0.5,
    offsetY = 0.5
  ) => {
    const childRatio = childWidth / childHeight
    const parentRatio = parentWidth / parentHeight
    let width = parentWidth * scale
    let height = parentHeight * scale

    if (contains ? childRatio > parentRatio : childRatio < parentRatio) {
      height = width / childRatio
    } else {
      width = height * childRatio
    }

    return {
      height,
      offsetX: (parentWidth - width) * offsetX,
      offsetY: (parentHeight - height) * offsetY,
      width
    }
  }
}

export const contain = fit(true)
export const cover = fit(false)

export const convertToBase64Png = (
  img: HTMLImageElement,
  config?: {
    width: number
    height: number
    contain?: boolean
  }
) => {
  const canvas = document.createElement("canvas")

  const ctx = canvas.getContext("2d")!
  if (config) {
    canvas.width = config.width
    canvas.height = config.height
    if (config.contain) {
      const { offsetX, offsetY, width, height } = contain(
        config.width,
        config.height,
        img.width,
        img.height
      )

      ctx.drawImage(img, offsetX, offsetY, width, height)
    } else {
      const { offsetX, offsetY, width, height } = cover(
        config.width,
        config.height,
        img.width,
        img.height
      )
      ctx.drawImage(img, offsetX, offsetY, width, height)
    }
  } else {
    canvas.width = img.width
    canvas.height = img.height
    ctx.drawImage(img, 0, 0, img.width, img.height)
  }

  var dataURL = canvas.toDataURL("image/png")
  //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  return dataURL
}

export const loadImage = async (imgUrl: string): Promise<LoadedImage> => {
  return new Promise((resolve) => {
    const img = new Image()

    img.onload = () =>
      resolve({
        height:   img.height,
        image:    img,
        toBase64: () => convertToBase64Png(img),
        url:      imgUrl,
        width:    img.width
      })

    img.onerror = async () => {
      img.src = logos.noImage

      return resolve({
        height:   img.height,
        image:    img,
        toBase64: () => convertToBase64Png(img),
        url:      logos.noImage,
        width:    img.width
      })
    }

    img.src = imgUrl
  })
}
