const FORM_ID = {
  ACCOUNT_DETAILS:                 "AccountDetailsForm",
  ADD_NEW_PROPERTY:                "AddNewProperty",
  ADD_NOTE:                        "AddNote",
  BOOKING:                         "Booking",
  CHUBB_CONTRACT:                  "ChubbContract",
  CHUBB_CUSTOMER:                  "ChubbCustomer",
  EMERGENCY_CONTACT:               "SiteContactForm",
  REPORT_RECIPIENT:                "RecipientForm",
  SELECT_ACCOUNT_FOR_NEW_PROPERTY: "SelectAccountForNewProperty",
  WEBFORM:                         "Webform"
}

export default FORM_ID
