import { JWTResponse, JWTErrorResponse } from './model/commonModel'
import { http } from './http'

export const idpAuth = (() => {

  const getAccessToken = async (userLogin: string, password: string, deviceKey?: string): Promise<JWTResponse | JWTErrorResponse> => {
    const body = new URLSearchParams({
      grant_type: "password",
      password,
      username:   userLogin
    })
    if (deviceKey) {
      body.append("device_code", deviceKey)
    }
    return http._handleResponse(
      fetch(`${process.env.REACT_APP_AUTH_PATH}/oauth2/token`, {
        body,
        headers: {
          Authorization:  `Basic ${process.env.REACT_APP_AUTH_BASIC}`,
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
      }), [400])
  }

  const getAccessTokenByRefreshToken = async (refreshToken: string, deviceKey: string): Promise<JWTResponse> => {
    const body = new URLSearchParams({
      device_code:   deviceKey,
      grant_type:    'refresh_token',
      refresh_token: refreshToken
    })
    const response = fetch(`${process.env.REACT_APP_AUTH_PATH}/oauth2/token`, {
      body,
      headers: {
        Authorization:  `Basic ${process.env.REACT_APP_AUTH_BASIC}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
      method: "POST"
    })
    return http._handleResponse(response, [400])
  }

  return {
    getAccessToken,
    getAccessTokenByRefreshToken
  }
})()
