import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import { IconSpinner } from "@icons"
import "./Spinner.css"
import { observer } from "mobx-react"
import { REACT_NBSP } from "@consts/config"
import { useAppState } from "@state/appState"
import classNames from "classnames"

type SpinnerProps = JSX.IntrinsicAttributes &
  React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & {
    testId?: string
  }

export const Spinner = (props: SpinnerProps) => {
  return (
    <div className="spinner" {...props}>
      <IconSpinner />
    </div>
  )
}

export const SpinnerInline = (props: SpinnerProps) => {
  return (
    <div
      className="spinner spinner--inline"
      data-testid="spinner-inline"
      {...props}
    >
      <IconSpinner />
    </div>
  )
}

export const SpinnerCentered = ({ testId, ...rest }: SpinnerProps) => {
  return (
    <div
      className="spinner spinner--centered"
      {...(testId ? { ["data-testid"]: testId } : {})}
      {...rest}
    >
      <IconSpinner />
    </div>
  )
}

export const SpinnerPadded = (props: SpinnerProps) => {
  return (
    <div
      className="spinner spinner--padded"
      data-testid="spinner-padded"
      {...props}
    >
      <IconSpinner />
    </div>
  )
}

export const SpinnerPage = (props: SpinnerProps) => {
  return (
    <div
      className="spinner spinner--page"
      data-testid="spinner-page"
      {...props}
    >
      <IconSpinner />
    </div>
  )
}

export const FixedSpinner: FC<{
  loading: boolean | number
  label?: JSX.Element | string
}> = ({ loading, label }) => {
  const [closing, setClosing] = useState(false)
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const [shown, setShown] = useState(false)

  const show = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    setClosing(false)
    setShown(true)
  }, [])

  const hide = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    setClosing(true)
    timeout.current = setTimeout(() => {
      setShown(false)
      setClosing(false)
    }, 500)
  }, [])

  useEffect(() => {
    if (loading) {
      show()
    } else {
      hide()
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [hide, loading, show])

  if (!shown) {
    return null
  }

  return (
    <div
      className={classNames({
        closing:                  !!closing,
        shown:                    !!shown,
        "spinner spinner--fixed": true
      })}
    >
      <span>{label}</span>
      <IconSpinner />
    </div>
  )
}

export const BigSpinner = observer(() => {
  const { bigSpin } = useAppState()
  const closingTimeout = useRef<NodeJS.Timeout | null>(null)
  const [closing, setClosing] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!bigSpin) {
      setClosing(true)
      closingTimeout.current = setTimeout(() => {
        setClosing(false)
        setShow(false)
      }, 500)
    } else {
      if (closingTimeout.current) {
        clearTimeout(closingTimeout.current)
      }
      setShow(true)
      setClosing(false)
    }
    return () => {
      if (closingTimeout.current) {
        clearTimeout(closingTimeout.current)
      }
    }
  }, [bigSpin])

  return (
    <div
      className={classNames({
        closing:                !!closing,
        show:                   !!show,
        "spinner spinner--big": true
      })}
    >
      <div className="spinner-container">
        <IconSpinner />
      </div>
    </div>
  )
})

export const MaterialSpinner = () => {
  return (
    <div className="MaterialSpinner">
      <div className="svg-wrapper">
        <svg className="spinner-svg" viewBox="0 0 50 50">
          <circle
            className="path"
            cx={25}
            cy={25}
            r={20}
            fill="none"
            strokeWidth={5}
          />
        </svg>
      </div>
    </div>
  )
}

export const SpinnerInputPlaceholder = ({
  label = true,
  labelText,
  testId,
  ...rest
}: {
  label?: boolean
  labelText?: string
  testId?: string
} & SpinnerProps) => {
  return (
    <div
      className={classNames({
        "spinner spinner--placeholder": true,
        "spinner--placeholder-nolabel": !label
      })}
      {...(testId ? { "data-testid": testId } : {})}
      {...rest}
    >
      <label className="input-label">{labelText || REACT_NBSP}</label>
      <div className="spinner--placeholder__container">
        <IconSpinner />
      </div>
    </div>
  )
}
