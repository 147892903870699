export const detectIE = () => {
  const ua = window.navigator.userAgent

  const msie = ua.indexOf("MSIE ")
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10)
  }

  const trident = ua.indexOf("Trident/")
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf("rv:")
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10)
  }

  // other browser
  return false
}

export const detectEdge = () => {
  const ua = window.navigator.userAgent

  let edge = ua.indexOf("Edge/")
  if (edge > 0) {
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10)
  }

  return false
}

export const detectChrome = () => {
  if ((window as any).chrome) {
    return true
  }
  return false
}

export const detectFirefox = () => {
  const ua = window.navigator.userAgent

  let firefox = ua.indexOf("Gecko/")
  if (firefox > -1) {
    return true
  }
  return false
}

export const detectSafariIos = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod"
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}
