import _ from "lodash"
import { MOBILE_BREAKPOINT_WIDTH } from "../consts/config"

export const isDevelopment = () => {
  return window.location.host.split('.')[0]?.includes('testing')
    || window.location.host.split('.')[0]?.includes('staging')
    || window.location.host.split('.')[0]?.includes('localhost')
}

export const rgbToHsl = (r: number, g: number, b: number) => {
  ;(r /= 255), (g /= 255), (b /= 255)
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  var h: number = 0,
    s: number,
    l = (max + min) / 2

  if (max == min) {
    h = s = 0 // achromatic
  } else {
    var d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return [h, s, l]
}

export const hslToRgb = (h: number, s: number, l: number) => {
  var r: number, g: number, b: number

  if (s == 0) {
    r = g = b = l // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) {
t += 1
}
      if (t > 1) {
t -= 1
}
      if (t < 1 / 6) {
return p + (q - p) * 6 * t
}
      if (t < 1 / 2) {
return q
}
      if (t < 2 / 3) {
return p + (q - p) * (2 / 3 - t) * 6
}
      return p
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s
    var p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return [r * 255, g * 255, b * 255]
}

export const colorToRGBA = (function () {
  var canvas = document.createElement("canvas")
  canvas.width = canvas.height = 1
  var ctx = canvas.getContext("2d")!

  return _.memoize(function (col) {
    ctx.clearRect(0, 0, 1, 1)
    // In order to detect invalid values,
    // we can't rely on col being in the same format as what fillStyle is computed as,
    // but we can ask it to implicitly compute a normalized value twice and compare.
    ctx.fillStyle = "#000"
    ctx.fillStyle = col
    var computed = ctx.fillStyle
    ctx.fillStyle = "#fff"
    ctx.fillStyle = col
    if (computed !== ctx.fillStyle) {
      return // invalid color
    }
    ctx.fillRect(0, 0, 1, 1)
    return [...ctx.getImageData(0, 0, 1, 1).data]
  })
})()

export const IS_MOBILE = () => window.innerWidth < MOBILE_BREAKPOINT_WIDTH

export const swapKeyValue = (json: { [name: string]: string | number }) => {
  var ret = {}
  for (var key in json) {
    ret[json[key]] = key
  }
  return ret
}

export const wait = async (delay: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, delay)
  })
}

export function debounce(func: () => any, wait: number, immediate?: boolean) {
  var timeout: number | null
  return function (this: any) {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) {
func.apply(context, args as any)
}
    }
    var callNow = immediate && !timeout
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = window.setTimeout(later, wait)
    if (callNow) {
func.apply(context, args as any)
}
  }
}

export function throttle(callback: () => any, limit: number) {
  var waiting = false
  return function (this: any) {
    if (!waiting) {
      callback.apply(this, arguments as any)
      waiting = true
      setTimeout(function () {
        waiting = false
      }, limit)
    }
  }
}

// -50% --------- 0%-----30%-----60%----100%
// -1.5 ----@--- -1 -------- 0 --------- 1
export const interpolate = (
  x: number,
  config: {
    from: [number, number]
    to: [number, number]
    clamp?: boolean
  }
) => {
  const handlePair = (
    x: number,
    {
      from,
      to,
      clamp
    }: {
      from: [number, number]
      to: [number, number]
      clamp?: boolean
    }
  ) => {
    let pos = x
    if (clamp) {
      if (from[1] - from[0]) {
        pos = Math.min(Math.max(pos, from[0]), from[1])
      } else {
        pos = Math.max(Math.min(pos, from[0]), from[1])
      }
    }
    const progress = (pos - from[0]) / (from[1] - from[0])
    const res = progress * (to[1] - to[0]) + to[0]
    return res
  }

  return handlePair(x, config)
}

export const setTabTitle = (title: string) => {
  //const id = uniqueId(); // not needed now, internal obj ref is enough
  document.title = title
  if (!window.titleHistory) {
    window.titleHistory = []
  }
  const entry = { title }
  window.titleHistory.push(entry)
  return () => {
    if (window.titleHistory.indexOf(entry) === window.titleHistory.length - 1) {
      window.titleHistory.pop()
      if (window.titleHistory.length) {
        document.title =
          window.titleHistory[window.titleHistory.length - 1].title
      } else {
        //document.title = "The Keyholding Company";
      }
    } else {
      window.titleHistory.splice(window.titleHistory.indexOf(entry), 1)
    }
  }
}

export const doFlyingDownloadIcon = (x, y) => {
  const svg = document.createElement("div")
  svg.style.position = "fixed"
  svg.style.left = x + "px"
  svg.style.top = y + "px"
  svg.style.transform = "translate(-50%,-50%)"
  svg.style.width = "24px"
  svg.style.height = "24px"
  svg.style.pointerEvents = "none"
  svg.style.opacity = "0"

  svg.innerHTML = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--text)" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4ZM8.4082 16.872C8.4082 16.7063 8.54252 16.572 8.7082 16.572H15.2918C15.4575 16.572 15.5918 16.7063 15.5918 16.872V17.2298C15.5918 17.3955 15.4575 17.5298 15.2918 17.5298H8.7082C8.54252 17.5298 8.4082 17.3955 8.4082 17.2298V16.872ZM10.5633 8.40244C10.5633 8.323 10.6285 8.25861 10.7088 8.25861H13.2912C13.3716 8.25861 13.4367 8.323 13.4367 8.40244V12.3168H15.4517C15.5806 12.3168 15.6409 12.4781 15.5441 12.5642L12.0924 15.6339C12.0396 15.6809 11.9604 15.6809 11.9076 15.6339L8.45594 12.5642C8.35915 12.4781 8.4194 12.3168 8.54834 12.3168H10.5633V8.40244Z"/>
  </svg>

  `
  document.body.appendChild(svg)

  setTimeout(() => {
    svg.style.left = window.innerWidth - 50 + "px"
    svg.style.top = 0 + "px"
    svg.style.opacity = "1"
    svg.style.transition =
      "left 2000ms var(--bezier4), top 2000ms var(--bezier4)"
    setTimeout(() => {
      svg.style.top = window.innerHeight + "px"
      svg.style.transition =
        "left 2000ms var(--bezier4), top 2000ms var(--bezier4), opacity 900ms"
      svg.style.opacity = "0"
    }, 150)
  }, 200)
  setTimeout(() => {
    svg.parentElement?.removeChild(svg)
  }, 2000)
}

export const getDiv = (selector: string) => {
  return document.querySelector(selector) as HTMLDivElement
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + " B"
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + " " + units[u]
}
