import { observer } from "mobx-react"
import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { Downloadee } from "@api/model/jobModel"
import { AppState } from "@state/appState"
import { downloadContext } from "@state/downloadState"
import { blobToFile } from "@utils/blobToFile"
import { IconDownload } from "@icons"
import { SpinnerInline } from "../spinner/Spinner"
import "./Downloads.css"

export const Downloads = observer(() => {
  const DLS = useContext(downloadContext)

  return (
    <>
      <div className="modal-content">
        <div className="container">
          <div className="title">
            <h3>Downloads</h3>
            <span>
              {DLS.downloads.size} file
              {DLS.downloads.size > 1 ? "s" : ""} remaining
            </span>
          </div>

          <div className="downloads-container">
            {Array.from(DLS.downloads, ([key, download]) => {
              return (
                <SingleDownload download={download} key={key} keyProp={key} />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
})

interface SingleDownloadProps {
  download: Downloadee
  keyProp: string
  appState?: AppState
}

const SingleDownload: FC<SingleDownloadProps> = observer((props) => {
  const DLS = useContext(downloadContext)

  const [manual, setManual] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const handleClick = () => {
    const { download, keyProp } = props
    openFile(
      (download.file! as File).name
        ? (download.file! as File)
        : blobToFile(download.file!, download.fileName)
    ).then(() => {
      hideAndRemove(keyProp)
    })
  }

  const hideAndRemove = (key: string) => {
    const downloadEl = ref.current
    if (!downloadEl) {
      return
    }
    downloadEl.style.height = "0px"
    downloadEl.style.opacity = "0"
    downloadEl.style.transform = "scale(0.5) translate(25px,-25px)"
    timeout.current = setTimeout(() => {
      DLS.removeDownload(key)
    }, 500)
  }

  useEffect(() => {
    const { download, keyProp } = props
    if (download.file) {
      timeout.current = setTimeout(() => {
        openFile(
          (download.file! as File).name
            ? (download.file! as File)
            : blobToFile(download.file!, download.fileName)
        )
          .then(() => {
            hideAndRemove(keyProp)
          })
          .catch(() => {
            setManual(true)
          })
      }, 1000)
    }
  }, [props])

  const { download } = props
  if (download.fileName !== "") {
    const width = (download.downloaded * 100) / download.total
    return (
      <div className="progress" ref={ref}>
        <div className="progress-container">
          <div
            className="bar"
            style={{
              background: `linear-gradient(to right, var(--primary1) ${width}%, transparent 0)`
            }}
          >
            <div
              className="text"
              style={{
                background: `linear-gradient(to right, var(--text-on-primary) ${width}%, var(--text) 0)`
              }}
            >
              <div className="filename">{download.fileName}</div>
              {manual ? (
                <a onClick={handleClick} href="" className="btn-download">
                  <IconDownload />
                </a>
              ) : (
                <div className="size">
                  {getHumanFileSize(download.downloaded) +
                    " / " +
                    getHumanFileSize(download.total)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="preparing">
      <div className="preparing-container">
        <SpinnerInline />
        <span>Preparing file...</span>
      </div>
    </div>
  )
})

const getHumanFileSize = (bytes: number) => {
  let size = (bytes / 1000).toFixed(2)
  let grade = "KB"
  if (parseFloat(size) > 1000) {
    size = (parseFloat(size) / 1000).toFixed(2)
    grade = "MB"
  }
  if (parseFloat(size) > 1000) {
    size = (parseFloat(size) / 1000).toFixed(2)
    grade = "GB"
  }
  return size + grade
}

const openFile = (file: File) => {
  return new Promise<void>((resolve) => {
    if (typeof window.navigator.msSaveOrOpenBlob === "function") {
      window.navigator.msSaveOrOpenBlob(file, file.name)
      resolve()
      return
    }
    const a = document.createElement("a")
    a.href = URL.createObjectURL(file)
    a.download = file.name
    a.click()
    // const newWindow = window.open("about:blank", "window");
    // if (!newWindow) {
    //   reject();
    //   return;
    // }
    // if (newWindow.document.readyState === "complete") {
    //   openFileHelper(newWindow, file);
    // } else {
    //   newWindow.onload = () => {
    //     openFileHelper(newWindow, file);
    //   };
    // }
    resolve()
  })
}
/*
const openFileHelper = (newWindow: Window, file: File) => {
  const fileUrl = URL.createObjectURL(file);
  const fileObj = document.createElement("object");
  fileObj.style.position = "fixed";
  fileObj.style.top = "0px";
  fileObj.style.left = "0px";
  fileObj.style.bottom = "0px";
  fileObj.style.right = "0px";
  fileObj.style.width = "100%";
  fileObj.style.height = "100%";
  fileObj.data = fileUrl;
  fileObj.type = file.type;
  fileObj.name = file.name || "KeyHolding Job Report";
  newWindow.name = file.name || "KeyHolding Job Report";
  newWindow.document.write(
    "<html><head><title>" +
      (file.name || "KeyHolding Job Report") +
      "</title></head><body></body></html>"
  );
  newWindow.document.body.append(fileObj);
}; */
