import { observer } from "mobx-react-lite"
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { CSSTransition } from "react-transition-group"
import { Subject } from "rxjs"
import { filter } from "rxjs/operators"
import { logoutEvent } from "@state/appState"
import { offsetHtmlScrollbar } from "@utils/getScrollbarWidth"
import {
  modalCloseSubject,
  modalZIndexSubject,
  MODAL_ZINDEX
} from "../modals/Modals"
import { IconClose } from "@icons"
import "./Modal.css"

interface ModalCommonProps {
  content: React.FC<ModalProps> | React.ComponentType<ModalProps>
  id: string
  className?: string
  closeable?: boolean
  props?: { [name: string]: any }
  popup?: boolean
}

export interface ModalProps {
  showModal: boolean
  closeModal: () => void
}

export const modalClosedSubject = new Subject<string>()
export const modalClosedEvent = modalClosedSubject.asObservable()

export const Modal: FC<ModalCommonProps> = observer(
  ({ id, className, content, popup, closeable = true, props = {} }) => {
    const [showModal, setShowModal] = useState(false)
    const [clickStartedOutside, setClickStartedOutside] = useState(false)
    const MemoizedContent = useMemo(() => content, [content]) // useless?
    const [zIndex, setZIndex] = useState(MODAL_ZINDEX)

    useEffect(() => {
      setShowModal(true)
    }, [])

    const closeModal = () => {
      setShowModal(false)
    }

    useEffect(() => {
      const subRemove = modalCloseSubject
        .pipe(filter(({ id: idToClose }) => idToClose === id))
        .subscribe(() => {
          setShowModal(false)
        })
      const subZindex = modalZIndexSubject
        .pipe(filter(({ id: idToIncrement }) => idToIncrement === id))
        .subscribe(({ zIndex }) => {
          setZIndex(zIndex)
        })
      const subAuth = logoutEvent.subscribe(() => {
        closeModal()
      })
      return () => {
        subZindex.unsubscribe()
        subRemove.unsubscribe()
        subAuth.unsubscribe()
      }
    }, [])

    useEffect(() => {
      if (showModal && !popup) {
        offsetHtmlScrollbar(true)
      } else {
        const modalsCount = document.querySelectorAll(".Modal").length
        if (modalsCount <= 1) {
          offsetHtmlScrollbar(false)
        }
      }
    }, [showModal])

    useEffect(() => {
      return () => {
        const modals = document.querySelectorAll(".Modal")
        if (!modals.length) {
          offsetHtmlScrollbar(false)
        }
      }
    }, [])

    const closeModalHandler = () => {
      if (closeModal) {
        closeModal()
      }
    }

    const useHookWithRefCallback = () => {
      const ref = useRef<null | any>(null)
      const setRef = useCallback((node: HTMLDivElement) => {
        const checkFocus = (e: KeyboardEvent) => {
          const current = e.target as HTMLElement
          const shiftPressed = e.shiftKey
          if (e.key === "Tab") {
            e.stopPropagation()
            if (node && node.contains(current)) {
              const elements = node.querySelectorAll(
                'button, input:not([style*="display:none"]), textarea, select, div.select-selected'
              )

              let borderElem = shiftPressed
                ? elements[0]
                : elements[elements.length - 1]

              if (current.isEqualNode(borderElem)) {
                if (shiftPressed) {
                  ;(elements[elements.length - 1] as HTMLElement).focus()
                } else {
                  ;(elements[0] as HTMLElement).focus()
                }

                e.preventDefault()
                e.stopPropagation()
              } else {
                return true
              }
            }
          }

          if (e.key === "Escape" || e.key === "Esc") {
            closeModalHandler()
            e.stopPropagation()
          }

          return true
        }

        const checkFocusGlobal = (e: KeyboardEvent) => {
          if (!ref.current) {
            document.removeEventListener("keydown", checkFocusGlobal)
            return
          }
          if (e.key === "Tab") {
            const elements = node.querySelectorAll(
              'button, input:not([style*="display:none"]), textarea, select, div.select-selected'
            )
            if (!elements.length) {
              return
            }
            ;(elements[0] as HTMLElement).focus()
            e.preventDefault()
            e.stopPropagation()
          }

          if (e.key === "Escape") {
            closeModalHandler()
            e.stopPropagation()
          }

          return true
        }

        if (ref.current) {
          ref.current.removeEventListener("keydown", checkFocus)
          document.removeEventListener("keydown", checkFocusGlobal)
        }

        if (node) {
          node.addEventListener("keydown", checkFocus)
          document.addEventListener("keydown", checkFocusGlobal)
          node.focus()
        }

        ref.current = node
      }, [])

      return [setRef]
    }

    const [modalRef] = useHookWithRefCallback()

    useEffect(() => {
      if (!showModal && document.querySelectorAll(".Modal").length === 1) {
        offsetHtmlScrollbar(false)
      } else if (showModal && !popup) {
        offsetHtmlScrollbar(true)
      }
      return () => {
        if (document.querySelectorAll(".Modal").length === 1) {
          offsetHtmlScrollbar(false)
        }
      }
    }, [showModal])

    const checkClickDown = (e: React.MouseEvent) => {
      if ((e.target as HTMLElement).id === id) {
        setClickStartedOutside(true)
      }
    }

    const onClick = () => {
      if (!clickStartedOutside) {
        return
      }
      if (!closeable || popup) {
        return
      }
      setClickStartedOutside(false)
      closeModalHandler()
    }

    const modalWindowRef = useRef<HTMLDivElement | null>(null)

    const clickX = useRef(0)
    const clickY = useRef(0)
    const lastDeltaX = useRef(0)
    const lastDeltaY = useRef(0)
    const dragging = useRef(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
      dragging.current = true
      clickX.current = e.clientX - lastDeltaX.current
      clickY.current = e.clientY - lastDeltaY.current
      document.onpointerup = pointerUp
      document.onpointermove = pointerMove
      modalWindowRef.current!.style.userSelect = "none"
    }

    const pointerUp = () => {
      dragging.current = false
      clickY.current = 0
      clickX.current = 0
      document.onpointerup = null
      document.onpointermove = null
      modalWindowRef.current!.style.userSelect = "all"
    }

    const pointerMove = (e: PointerEvent) => {
      if (!dragging.current) {
        return
      }
      const deltaX = e.clientX - clickX.current
      const deltaY = e.clientY - clickY.current
      lastDeltaX.current = deltaX
      lastDeltaY.current = deltaY

      requestAnimationFrame(() => {
        modalWindowRef.current!.style.top = deltaY + "px"
        modalWindowRef.current!.style.left = deltaX + "px"
      })
    }

    return (
      <CSSTransition
        in={showModal}
        unmountOnExit
        onExited={() => modalClosedSubject.next(id)}
        timeout={300}
        classNames="modal-fade"
      >
        <div
          tabIndex={-1}
          id={id}
          style={{
            zIndex: zIndex + 2000
          }}
          className={
            "Modal modal-fade " +
            (className ? className : "") +
            (popup ? " modal-popup" : "")
          }
          onMouseDown={checkClickDown}
          onClick={onClick}
          ref={modalRef}
        >
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
            //onPointerDown={pointerDown}
            ref={modalWindowRef}
            className="modal-wrapper"
          >
            {closeable && !popup ? (
              <span className="close-button" onClick={closeModal}>
                <IconClose />
              </span>
            ) : null}
            {/* {popup ? (
              <span
                className="close-button minimize-button"
                onClick={minimizeModal}
              >
                -
              </span>
            ) : null} */}

            <div
              className="modal-inner"
              onPointerDown={(e) => e.stopPropagation()}
            >
              <MemoizedContent
                showModal={showModal}
                closeModal={closeModalHandler}
                {...props}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
)
