import logos from "@images/logos"
import { loadImage } from "@utils/imageUtils"
import { wait } from "@utils/utils"

export const initSplashLogo = async () => {
  const logoEl = document.querySelector(".bs__logo") as HTMLImageElement
  const logoLoaderEl = document.querySelector(
    ".bs__logo-loader"
  ) as HTMLImageElement
  const upperEl = document.querySelector(".bs__upper") as HTMLImageElement
  const spinnerEl = document.querySelector(".bs__spinner") as HTMLDivElement
  const logoSpinnerInner = document.querySelector(
    ".bs__logo-spinner__inner"
  ) as HTMLImageElement
  // theme logo needs to be reloaded, otherwise it will disappear from bootsplash after being reappended to sidebar
  // note: caching of theme logo is disabled on backend
  const logo = window.theme
    ? await loadImage(window.theme.logo.url)
    : await loadImage(logos.logo)
  logoSpinnerInner.style.transform = "rotate(180deg)"
  await wait(500)
  logoLoaderEl.style.transform = "scale(0)"
  await wait(500)
  upperEl.removeChild(logoLoaderEl)
  logoEl.style.opacity = "1"

  logoEl.appendChild(logo.image)
  await wait(1000)
  spinnerEl.style.opacity = "1"
}

export const initBootsplash = async () => {
  window.showBootsplashCheckmark = function () {
    const spinnerSvg = document.querySelector(".bs__icon--spinner")
    const checkmarkSvg = document.querySelector(".bs__icon--checkmark")
    if (!spinnerSvg || !checkmarkSvg) {
      return
    }
    spinnerSvg.classList.add("bs__icon--hide")
    checkmarkSvg.classList.remove("bs__icon--hide")
  }

  window.hideBootsplash = function () {
    const splash = document.querySelector(".Bootsplash") as HTMLDivElement
    const spinnerEl = document.querySelector(".bs__spinner") as HTMLDivElement
    const textEl = document.querySelector(".bs__text") as HTMLDivElement
    const logoEl = document.querySelector(".bs__logo") as HTMLDivElement
    if (!textEl || !splash || !spinnerEl || !logoEl) {
      return
    }
    setTimeout(function () {
      spinnerEl.style.opacity = "0"
      textEl.style.opacity = "0"
      setTimeout(function () {
        logoEl.style.opacity = "0"
        textEl.style.height = "0"
        textEl.style.paddingBottom = "0"
        setTimeout(function () {
          splash.classList.add("hide")
          setTimeout(function () {
            splash.parentElement?.removeChild(splash)
          }, 1000)
        }, 100)
      }, 250)
    }, 700)
  }

  window.setBootsplashText = async function (text) {
    const textEl = document.querySelector(".bs__text") as HTMLDivElement
    const spinnerEl = document.querySelector(".bs__spinner") as HTMLDivElement
    if (!textEl) {
      return
    }
    await wait(250)
    if (textEl.innerHTML.length) {
      textEl.style.opacity = "0"
      setTimeout(function () {
        textEl.innerHTML = text
        textEl.style.opacity = "1"
      }, 500)
    } else {
      textEl.innerHTML = text
      textEl.style.paddingBottom = "20px"
      textEl.style.height = textEl.scrollHeight + "px"
      setTimeout(function () {
        textEl.style.opacity = "1"
        spinnerEl.style.opacity = "1"
      }, 300)
    }
  }
}
