import { observable } from "mobx"
import { createContext } from "react"
import { Subject } from "rxjs"
import { Downloads } from "@components/downloads/Downloads"
import { toggleModal } from "@components/modals/Modals"
import { Downloadee } from "@api/model/jobModel"

export const downloadSubject = new Subject<{
  key: string
  download?: Downloadee
  delete?: boolean
}>()
export const downloadEvent = downloadSubject.asObservable()

class DownloadState {
  constructor() {
    downloadEvent.subscribe({
      next: (e) => {
        if (e.delete) {
          this.removeDownload(e.key)
        } else if (e.download) {
          this.setDownload(e.key, e.download)
        }
      }
    })
  }

  @observable
  downloads: Map<string, Downloadee> = new Map()

  setDownload(key: string, download: Downloadee) {
    if (this.downloads.size === 0) {
      this.open()
    }
    this.downloads.set(key, download)
  }

  removeDownload(key: string) {
    this.downloads.delete(key)
    if (this.downloads.size === 0) {
      this.close()
    }
  }

  close = () => {
    toggleModal({
      closeAll: true,
      id:       "Downloads",
      open:     false
    })
  }

  open = () => {
    toggleModal({
      component: Downloads,
      id:        "Downloads",
      open:      true,
      popup:     true
    })
  }
}

export const downloadContext = createContext(new DownloadState())
