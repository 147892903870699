import { Asset } from "@api/model/propertyModel"
import { AccessPermissions } from "@api/model/accessModel"

export interface Contact {
  newItemId?: string
  id?: string | null
}

export enum DARK_MODE {
  LIGHT = "LIGHT",
  DARK = "DARK",
  AUTO = "AUTO"
}

export interface AssetResponse {
  count: number
  assets: Asset[]
}

export type ServerResponse<T> = ServerResponseGood<T> | ServerResponseBad

export interface ServerResponseGood<T> {
  error: false
  data: T
  // code: any; todo
}

export interface ServerResponseBad {
  error: true
  data: string
  // code: any;
}
export interface JWTErrorResponse {
  error: true
  error_description: string
  code: number
}

// export enum PermissionPriority {
//   DISABLED,
//   READ_ONLY_AI_HIDDEN,
//   AI_HIDDEN,
//   BESPOKE,
//   READ_ONLY,
//   ENABLED,
// }

export interface UiPermissions {
  twoFactorType: "sms" | "email" | null
  whiteLabelProfile: string
  etaAllowed?: boolean
  serviceTracId: boolean
  serviceTracIdMandatory: boolean
  checkFileUploadAllowed: boolean
}

export interface SessionInfo {
  authType: "PASSWORD" | "SOMETHING_ELSE?"
  username: string
  permissions: AccessPermissions
  uiPermissions: UiPermissions
  fullName: string
  accountNumbers: number[]
  phone: string
  deviceKey?: string
}

export interface ChaseConfig {
  jobAmend: {
    minBeforeAmendMinutes: number
    minBeforeJobDateMinutes: number
  }
}

export interface LoginErrorResponse extends ServerResponseBad {
  errorCode: AuthErrorCode
}

export enum AuthErrorCode {
  UNKNOWN_LOGIN_OR_PASSWORD = "UNKNOWN_LOGIN_OR_PASSWORD",
  USER_LOCKED = "USER_LOCKED",
  PASSWORD_NOT_ALLOWED = "PASSWORD_NOT_ALLOWED",
  PASSWORD_NOT_SET = "PASSWORD_NOT_SET",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  MOBILE_ACCESS_ONLY = "MOBILE_ACCESS_ONLY",
  NO_PORTAL_ACCESS = "NO_PORTAL_ACCESS",
  EMPTY_FIELDS = "EMPTY_FIELDS",
  INACTIVITY = "INACTIVITY",
  SESSION_EXPIRED = "SESSION_EXPIRED",
  CONNECTION_ERROR = "CONNECTION_ERROR",
  TOKEN_LOGIN_ERROR = "TOKEN_LOGIN_ERROR",
  ACCESS_DENIED = "ACCESS_DENIED"
}

export enum JWTErrorDescription {
  BAD_CREDENTIALS = "Bad credentials",
  USER_LOCKED = "User locked",
  ACCESS_DENIED = "User access is not configured"
}

export interface KeyValue {
  key: string
  value: string
}

export interface GetAddressResponse {
  postcode: string
  latitude: number
  longitude: number
  addresses: GetAddressAddress[]
}

export interface GetAddressAddress {
  formatted_address: string[]
  thoroughfare: string
  building_name: string
  sub_building_name: string
  sub_building_number: string
  building_number: string
  line_1: string
  line_2: string
  line_3: string
  line_4: string
  locality: string
  town_or_city: string
  county: string
  district: string
  country: string
}

export interface FormattedGetAddress {
  fullAddress: string
  building: string
  city: string
  county: string
  postcode: string
  street: string
}

export interface WhiteLabelProfile {
  logo: string
  showPoweredBy: boolean
  urlName: string
  primaryColor: string
  secondaryColor: string
  showWidget: boolean
  keycloakEnabled: boolean
}

export type LoadedImage = {
  toBase64: () => string
  width: number
  height: number
  url: string
  image: HTMLImageElement
}

export enum DDEBIT_ERROR {
  INVALID = "INVALID",
  EXPIRED = "EXPIRED"
}

export interface DdebitFormRequest {
  bankAccountHolderName: string
  sortCode: string
  accountNumber: string
}

export interface What3WordsResponse {
  coordinates: {
    lat: number
    lng: number
  }
  words: string
  country: string
  language: string
  map: string
  nearestPlace: string
  square: {
    southWest: {
      lat: number
      lng: number
    }
    northEast: {
      lat: number
      lng: number
    }
  }
}

export interface JWTResponse {
  access_token: string
  refresh_token: string
  token_type: string
  expires_in: number
  error_description?: string
  error?: string
}
