import { DARK_MODE } from "@api/model/commonModel"
import { colorToRGBA, interpolate } from "@utils/utils"

export const generatePalette = (primary, accent) => {
  const palette = new ColorPalette(primary, accent)
  window.toggleDarkMode = palette.toggleDark
}

class ColorPalette {
  isDark = false
  isAccentBright = false
  isAccentFaintBright = false
  isPrimaryBright = false

  constructor(color: string, accentColor: string) {
    const clean = (color: number[]) => {
      return color.map((channel) =>
        Math.max(Math.min(Math.floor(channel), 255), 0)
      )
    }

    // convert primary and accent to rgb
    const primary1 = colorToRGBA(color) || [0, 222, 222]
    const accent1 = colorToRGBA(accentColor) || [231, 134, 175]

    // remove alpha
    while (primary1.length > 3) {
      primary1.pop()
    }
    while (accent1.length > 3) {
      accent1.pop()
    }

    const primaryNormalized = primary1.map((color) =>
      Math.max(primary1[0], primary1[1], primary1[2]) !== 0
        ? color / Math.max(primary1[0], primary1[1], primary1[2])
        : 0
    )
    const accentNormalized = accent1.map((color) =>
      Math.max(accent1[0], accent1[1], accent1[2]) !== 0
        ? color / Math.max(accent1[0], accent1[1], accent1[2])
        : 0
    )
    const accentBold = accentNormalized.map((accentRatio) => {
      if (accentRatio === 1) {
        return 250
      }
      return accentRatio * 80
    })

    let primary1Dark = [...primary1]

    // green is perceived as brighter at higher values,
    // multiply it for true color brightness
    const GREEN_MODIFIER = 9
    const GREEN_MODIFIER_DARK = 6

    const LIGHT_BRIGHTNESS_LIMIT = 2200 // colors will be reduced in brightness past this point
    const ACCENT_BRIGHTNESS_LIMIT = 2700
    const VERY_BRIGHT = 2000 // controls inversion of text
    const ACCENT_BOLD_BRIGHTNESS_LIMIT = 1600 // controls inversion of text
    const DARK_DARKNESS_LIMIT = 600 // colors will be brightened for dark theme below this point

    const getLightSum = (color: number[]) => {
      return color.reduce((a, b, index) => {
        if (index === 1) {
          return a + b * GREEN_MODIFIER
        }
        return a + b
      })
    }

    const getDarkSum = (color: number[]) => {
      return color.reduce((a, b, index) => {
        if (index === 1) {
          return a + b * GREEN_MODIFIER_DARK
        }
        return a + b
      })
    }

    const adjustOverbright = (colorArr) => {
      while (getLightSum(colorArr) > LIGHT_BRIGHTNESS_LIMIT) {
        colorArr.forEach((color, index) => {
          colorArr[index] *= 0.95
          //ratio * 220;
        })
      }
    }
    const adjustOverdark = (colorArr) => {
      while (getDarkSum(colorArr) < DARK_DARKNESS_LIMIT) {
        colorArr.forEach((color, index) => {
          colorArr[index] = Math.min(255, colorArr[index] * 1.05 + 15) // +15 in case it's something like #000
        })
      }
    }
    const adjustAccent = (colorArr) => {
      while (getLightSum(colorArr) > ACCENT_BRIGHTNESS_LIMIT) {
        colorArr.forEach((color, index) => {
          colorArr[index] *= 0.95
        })
      }
    }
    const adjustAccentBold = (colorArr) => {
      while (getLightSum(colorArr) > ACCENT_BOLD_BRIGHTNESS_LIMIT) {
        colorArr.forEach((color, index) => {
          colorArr[index] *= 0.95
        })
      }
    }

    // adjust overbright/overdark
    ;[primary1].forEach(adjustOverbright)
    ;[primary1Dark].forEach(adjustOverdark)
    adjustAccentBold(accentBold)
    adjustAccent(accent1)

    // generate shades
    const accent2 = clean(
      accent1.map((color) => {
        const factor = interpolate(color, {
          clamp: true,
          from:  [0, 255],
          to:    [1, 0.5]
        })
        const adjCol = color + 20 * factor
        return adjCol
      })
    )

    // generate primary shades
    const primary2 = clean(
      primary1.map((color, index) => {
        return (
          (color + 10) *
          interpolate(primaryNormalized[index], {
            clamp: true,
            from:  [0.4, 1],
            to:    [1.5, 1.4]
          }) *
          interpolate(primary1[1], {
            clamp: true,
            from:  [125, 255],
            to:    [1, 0.6]
          })
        )
      })
    )
    const primary3 = clean(
      primary1.map((color, index) => {
        return (
          color *
          interpolate(primaryNormalized[index], {
            clamp: true,
            from:  [0.4, 1],
            to:    [0.8, 0.9]
          })
        )
      })
    )

    // generate dark primary shades
    const primary2Dark = clean(
      primary1Dark.map((color, index) => {
        return (
          (color + 10) *
          interpolate(primaryNormalized[index], {
            clamp: true,
            from:  [0.4, 1],
            to:    [1.5, 1.4]
          })
        )
      })
    )

    const primary3Dark = clean(
      primary1Dark.map((color, index) => {
        return (
          color *
          interpolate(primaryNormalized[index], {
            clamp: true,
            from:  [0.4, 1],
            to:    [0.8, 0.9]
          })
        )
      })
    )

    // check if primary needs inverted text
    if (getLightSum(primary1) > VERY_BRIGHT) {
      this.isPrimaryBright = true
    }

    // check if accent needs inverted text
    if (getLightSum(accent1) > VERY_BRIGHT) {
      this.isAccentBright = true
    }

    // check if accent faint needs inverted text
    if (getLightSum(accent2) > VERY_BRIGHT) {
      this.isAccentFaintBright = true
    }

    let colors: { [name: string]: string } = {
      "accent-bold-light":  `rgb(${accentBold.join(",")})`,
      "accent-faint-light": `rgb(${accent2.join(",")})`,
      "accent-light":       `rgb(${clean(accent1).join(",")})`,
      panel:                `rgb(${clean(
        primaryNormalized.map((colorNormalized) => 245 + 2 * colorNormalized)
      ).join(",")})`,
      "primary1-dark":  `rgb(${clean(primary1Dark).join(",")})`,
      "primary1-light": `rgb(${clean(primary1).join(",")})`,
      "primary2-dark":  `rgb(${primary2Dark.join(",")})`,
      "primary2-light": `rgb(${primary2.join(",")})`,
      "primary3-dark":  `rgb(${primary3Dark.join(",")})`,
      "primary3-light": `rgb(${primary3.join(",")})`
    }

    if (this.isPrimaryBright) {
      colors = {
        ...colors,
        "text-on-primary":      "var(--content)",
        "text-on-primary-bold": "var(--content-bright)"
      }
    } else {
      colors = {
        ...colors,
        "text-on-primary":      "var(--content-bright-inverse)",
        "text-on-primary-bold": "var(--content-bright-inverse)"
      }
    }

    if (!this.isAccentBright) {
      colors = {
        ...colors,
        "accent-bold-light": "var(--content-bright-inverse)",
        "text-on-accent":    "var(--content-bright-inverse)"
      }
    } else {
      colors = {
        ...colors,
        "text-on-accent": "var(--content)"
      }
    }
    if (!this.isAccentFaintBright) {
      colors = {
        ...colors,
        "text-on-accent-faint": "var(--content-bright-inverse)"
      }
    } else {
      colors = {
        ...colors,
        "text-on-accent-faint": "var(--content-bright)"
      }
    }

    for (const [key, color] of Object.entries(colors)) {
      document.documentElement.style.setProperty(`--${key}`, color)
    }
  }

  toggleDark = (darkModePreference?: DARK_MODE) => {
    if (darkModePreference === DARK_MODE.DARK) {
      this.setDarkVars()
    } else {
      this.setLightVars()
    }
  }

  setDarkVars = () => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]")!
    metaThemeColor.setAttribute(
      "content",
      getComputedStyle(document.documentElement)
        .getPropertyValue("--background-dark")!
        .trim()
    )

    document.body.classList.add("dark-mode")
    this.isDark = true
    document.documentElement.style.setProperty(
      "--primary1",
      "var(--primary1-dark)"
    )
    document.documentElement.style.setProperty(
      "--primary2",
      "var(--primary2-dark)"
    )
    document.documentElement.style.setProperty(
      "--primary3",
      "var(--primary3-dark)"
    )
    document.documentElement.style.setProperty(
      "--background",
      "var(--background-dark)"
    )
    document.documentElement.style.setProperty("--accent", "var(--accent-dark)")
    document.documentElement.style.setProperty(
      "--accent-faint",
      "var(--accent-faint-dark)"
    )
    document.documentElement.style.setProperty(
      "--accent-bold",
      "var(--accent-bold-dark)"
    )
    document.documentElement.style.setProperty(
      "--text-on-accent",
      "var(--text-on-accent-dark)"
    )
    document.documentElement.style.setProperty(
      "--text-on-accent-faint",
      "var(--text-on-accent-dark)"
    )
    document.documentElement.style.setProperty(
      "--background-elements",
      "var(--background-elements-dark)"
    )
    document.documentElement.style.setProperty(
      "--input-hover",
      "var(--grey-hover-dark)"
    )
    document.documentElement.style.setProperty(
      "--background-faint",
      "var(--background-faint-dark)"
    )
    document.documentElement.style.setProperty(
      "--background-collapsable",
      "var(--background-collapsable-dark)"
    )
    document.documentElement.style.setProperty("--panel", "var(--panel-dark)")
    document.documentElement.style.setProperty("--border", "var(--border-dark)")
    document.documentElement.style.setProperty(
      "--text",
      "var(--content-inverse)"
    )
    document.documentElement.style.setProperty(
      "--text-bold",
      "var(--content-bright-inverse)"
    )
  }

  setLightVars = () => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]")!
    metaThemeColor.setAttribute(
      "content",
      getComputedStyle(document.documentElement)
        .getPropertyValue("--background-light")!
        .trim()
    )
    document.body.classList.remove("dark-mode")
    this.isDark = false
    document.documentElement.style.setProperty(
      "--primary1",
      "var(--primary1-light)"
    )
    document.documentElement.style.setProperty(
      "--primary2",
      "var(--primary2-light)"
    )
    document.documentElement.style.setProperty(
      "--primary3",
      "var(--primary3-light)"
    )
    document.documentElement.style.setProperty(
      "--background",
      "var(--background-light)"
    )
    document.documentElement.style.setProperty(
      "--accent",
      "var(--accent-light)"
    )
    document.documentElement.style.setProperty(
      "--accent-faint",
      "var(--accent-faint-light)"
    )
    document.documentElement.style.setProperty(
      "--accent-bold",
      "var(--accent-bold-light)"
    )

    document.documentElement.style.setProperty(
      "--background-elements",
      "var(--background-elements-light)"
    )
    document.documentElement.style.setProperty(
      "--input-hover",
      "var(--grey-hover-light)"
    )
    document.documentElement.style.setProperty(
      "--background-faint",
      "var(--background-faint-light)"
    )
    document.documentElement.style.setProperty(
      "--background-collapsable",
      "var(--background-collapsable-light)"
    )
    document.documentElement.style.setProperty("--panel", "var(--panel-light)")
    document.documentElement.style.setProperty(
      "--border",
      "var(--border-light)"
    )
    document.documentElement.style.setProperty("--text", "var(--content)")
    document.documentElement.style.setProperty(
      "--text-bold",
      "var(--content-bright)"
    )

    if (!this.isAccentBright) {
      document.documentElement.style.setProperty(
        "--text-on-accent",
        "var(--content-bright-inverse)"
      )
    } else {
      document.documentElement.style.setProperty(
        "--text-on-accent",
        "var(--content)"
      )
    }
    if (!this.isAccentFaintBright) {
      document.documentElement.style.setProperty(
        "--text-on-accent-faint",
        "var(--content-bright-inverse)"
      )
    } else {
      document.documentElement.style.setProperty(
        "--text-on-accent-faint",
        "var(--content-bright)"
      )
    }
  }
}
